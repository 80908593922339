


function Footer() {

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  return (
    <>
      {!userData.userData ? null :
        <div

          className="pclp-footer"
        >
          
            <div>Ondara Capital, S.L. (B67229757) es un sociedad supervisada y registrada en el Banco de España con el código de Entidad D552 - We Make Sure. © {new Date().getFullYear()} Ondara.</div>
          
        </div>

      }
    </>
  );
};

export default Footer;