
import { useState } from "react";
import { Button } from "@mui/material";

import Container from "@mui/material/Container";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from "@mui/material/Box";
import Ondara from '../../assets/ondara_inv.png';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';



import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';





/*
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));*/







function Header() {
    const userData = JSON.parse(sessionStorage.getItem("userData"));

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    return (
        <>
            {!userData.userData ? null :
                <Box sx={{ backgroundColor: '#000000', color: '#ffffff' }}>
                    <Container disableGutters maxWidth='xl' >

                        <Grid container spacing={0} sx={{ pt: 2 }}>
                            <Grid xs={6} lg={6} >
                                <Box sx={{ ml: 1, display: 'flex', justifyContent: 'left', alignItems: 'center', height: '60px' }}>
                                    <img src={Ondara} alt="Ondara Capital" />
                                </Box>
                            </Grid>
                            <Grid xs={6} lg={6} >
                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', height: '60px' }}>

                                    <Button variant="contained" onClick={handleClickOpen}>
                                        <PersonIcon sx={{ ml: 1 }} />
                                    </Button>
                                    <Button variant="contained" href="/logout">
                                        <LogoutIcon />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>




                        <Dialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            maxWidth="xl"
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Perfil de inversor
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <DialogContent dividers>

                                <table className="pclp-investordata">
                                    <tbody>
                                        <tr>
                                            <td>Nombre y apellidos:</td>
                                            <td>{userData.userData.Name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>{userData.userData.Email}</td>
                                        </tr>
                                        <tr>
                                            <td>Telefono:</td>
                                            <td>{userData.userData.Telefono}</td>
                                        </tr>
                                        <tr>
                                            <td>DNI/CIF:</td>
                                            <td>{userData.userData.DNI_CIF}</td>
                                        </tr>
                                        <tr>
                                            <td>Estado civil:</td>
                                            <td>{userData.userData.Estadi_civil}</td>
                                        </tr>
                                        <tr>
                                            <td>Dirección</td>
                                            <td>{userData.userData.Direcccion_a_efectos_de_notificacion}</td>
                                        </tr>
                                        <tr>
                                            <td>IBAN cuenta bancaria:</td>
                                            <td>{userData.userData.Num_Cuenta}</td>
                                        </tr>
                                        <tr>
                                            <td>Ocupación:</td>
                                            <td>{userData.userData.Ocupacion}</td>
                                        </tr>
                                        <tr>
                                            <td>Empresa:</td>
                                            <td>{userData.userData.Empresa}</td>
                                        </tr>
                                        <tr>
                                            <td>Apoderado de la empresa:</td>
                                            <td>{userData.userData.Nombre_apoderado_empresa}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Typography align="center" sx={{ margin: 4 }}>
                                    Si algun dato no es correcto, por favor informa a <a href="mailto:contacto@ondara.group">contacto@ondara.group</a>
                                </Typography>
                            </DialogContent>
                        </Dialog>


                    </Container>
                </Box>
            }
        </>
    );
};

export default Header;